.updateBtn {
  width: 60px;
  height: 30px;
  border-radius: 80px;
  font-size: 12px;
  border: none;
  background-color: #28a745;
  color: #fff;
  cursor: pointer;
}
.dltBtn {
  width: 60px;
  height: 30px;
  border-radius: 80px;
  font-size: 12px;
  border: none;
  background-color: #a03932;
  color: #fff;
  cursor: pointer;
}

.cssbuttons-io-button {
  text-transform: uppercase;
  cursor: pointer;
  background: #28a745;
  color: white;
  font-family: inherit;
  padding: 0.35em;
  padding-left: 1.2em;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0.9em;
  border: none;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 1.6em -0.6em #28a745;
  overflow: hidden;
  position: relative;
  height: 2.4em;
  padding-right: 3.3em;
}

.cssbuttons-io-button .icon {
  background: white;
  color: #714da6;
  font-weight: 700;
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.8em;
  width: 2.2em;
  border-radius: 0.7em;
  box-shadow: 0.1em 0.1em 0.6em 0.2em #28a745;
  right: 0.3em;
  transition: all 0.3s;
}

.cssbuttons-io-button:hover .icon {
  width: calc(100% - 0.6em);
  transition: all 0.3s;
}

.cssbuttons-io-button .icon svg {
  width: 1.1em;
  transition: transform 0.3s;
  color: #7b52b9;
}

.cssbuttons-io-button:hover .icon svg {
  transform: translateX(0.1em);
}

.cssbuttons-io-button:active .icon {
  transform: scale(0.95);
}

/* Add Btn */

.AddBtn {
  --color: #560bad;
  font-family: inherit;
  display: inline-block;
  width: 100%;
  height: 40px;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--color);
  transition: color 0.5s;
  z-index: 1;
  font-size: 17px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 30px;
  background-color: var(--color);
  color: #fff;
}

a {
  color: #eff5f544;
}
