* {
  margin: 0;
  padding: 0;
}
.product {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 22px;
  margin-top: 20px;
}

.product .left {
  flex: 1;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid rgba(177, 177, 177, 0.253);
}

.product .right {
  width: 30%;
  padding: 12px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid rgba(177, 177, 177, 0.253);
}
.product .right .lbl {
  font-weight: 500;
  font-size: 20px;
}

.imgDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  gap: 20px;
}

.imgDiv .img {
  width: 200px;
  height: auto;
  border-radius: 14px;
  margin-top: 40px;
}
.imgDiv img {
  width: 200px;
  height: auto;
  border-radius: 14px;
}

.auto {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.desc {
  margin-top: 30px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid rgba(177, 177, 177, 0.253);
}

.sub {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* Genel stil */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

/* Giriş stil */
.toggle-switch .toggle-input {
  display: none;
}

/* Anahtarın stilinin etrafındaki etiketin stil */
.toggle-switch .toggle-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 24px;
  background-color: rgb(158, 158, 158);
  border-radius: 34px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Anahtarın yuvarlak kısmının stil */
.toggle-switch .toggle-label::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  background-color: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s;
}

/* Anahtarın etkin hale gelmesindeki stil değişiklikleri */
.toggle-switch .toggle-input:checked + .toggle-label {
  background-color: #4caf50;
}

.toggle-switch .toggle-input:checked + .toggle-label::before {
  transform: translateX(16px);
}

/* Light tema */
.toggle-switch.light .toggle-label {
  background-color: #bebebe;
}

.toggle-switch.light .toggle-input:checked + .toggle-label {
  background-color: #9b9b9b;
}

.toggle-switch.light .toggle-input:checked + .toggle-label::before {
  transform: translateX(6px);
}
.subMAin {
  margin-right: 12px;
}

.delete {
  width: 200px;
  margin-top: 30px;
}
.inactive-row {
  background-color: hsl(0, 50%, 75%); /* Set your desired grey color */
}
@media screen and (max-width: 767px) {
  .product {
    flex-direction: column;
  }
  .product .right {
    width: 100%;
  }

  .imgDiv .img {
    width: 70%;
  }
  .imgDiv img {
    width: 100%;
  }
}
@media screen and (max-width: 667px) {
  .heading {
    font-size: 15px;
  }
}
@media screen and (max-width: 567px) {
  .delete {
    width: 100%;
    margin-top: 30px;
  }
  .imgDiv .img {
    width: 80%;
  }
  .imgDiv img {
    width: 100%;
  }
}

.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
  gap: 20px;
  padding: 15px;
  width: 100%;
  padding-left: 0;
}
.container-banner {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
  gap: 30px;
  padding: 15px;
  width: 100%;
  padding-left: 0;
}
.container .img {
  width: 200px;
  height: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.container-banner .img {
  border: 2px solid #4caf50;
  padding: 2px 10px;
  border-radius: 8px;
}
.container .upload {
  width: 200px;
  height: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.container img {
  width: 100%;
  display: block;
  transition: all 100ms ease-out;
}
.container img:hover {
  transform: scale(1.04);
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

@media screen and (max-width: 600px) {
  .container .img {
    width: 100%;
  }
}
